import axios from "axios";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    getProjectsBanners() {
      return new Promise((resolve, reject) => {
        return axios
          .get('betcore-banner/projects')
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBetcoreBanners({commit}, payload) {
      const params = {
        wl: payload
      }
      return new Promise((resolve, reject) => {
        return axios
          .get('betcore-banner', { params })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBetcoreStyles({commit}, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        return axios
          .post('product/betcore/getStyle',  payload )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    saveBetcoreStyles({commit}, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        return axios
          .post('product/betcore/setStyle', payload )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    betcoreBanners({commit}, {payload, wl}) {
      return new Promise((resolve, reject) => {
        return axios
          .put(`betcore-banner?wl=${wl}`, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
